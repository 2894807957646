import React from "react"
import { PageCenter } from "../../components/elements/wrappers"
import { Components, PostLink } from "../../components/elements"
import { graphql, useStaticQuery } from "gatsby"
import { DefaultHeader } from "../../components/elements"
import { Row, Col } from "react-bootstrap"
import { ModalX } from "../../components/elements/Modal.jsx"
import { ASKEGINewsletterForm } from "../../components/forms"
import { Page } from "../../components/elements"

export default function Component() {
    const PostLinks = ({ data }) => {
        return data.edges
            .filter(
                ({
                    node: {
                        frontmatter: { date },
                    },
                }) => !!date,
            )
            .map(({ node }, i) => <PostLink key={i} post={node} />)
    }

    const {
        q1,
        q2: {
            frontmatter: { id, components },
        },
    } = useStaticQuery(graphql`
        {
            q1: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "//news//" } }
                sort: { order: DESC, fields: frontmatter___date }
            ) {
                edges {
                    node {
                        ...NewsFields
                    }
                }
            }
            q2: markdownRemark(frontmatter: { id: { eq: "elem-01" } }) {
                ...ElementFields
            }
        }
    `)

    return (
        <Page
            title="News"
            description="Join our subscribers list to get the latest news & updates delivered directly in your inbox."
        >
            <PageCenter bg="white">
                <DefaultHeader title="EGI News" type="small" />
                <Row>
                    <Col>
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                            }}
                            data-testid="test-grid-parent"
                        >
                            <PostLinks data={q1} />
                        </div>
                    </Col>
                    <Col style={{ maxWidth: "fit-content" }}>
                        <div className="text-center mb-4">
                            <ModalX
                                title="Subscribe to ASK EGI"
                                subtitle="Join our subscribers list to get the latest news & updates delivered directly in your inbox."
                                button="Subscribe to ASK EGI"
                            >
                                <ASKEGINewsletterForm />
                            </ModalX>
                        </div>
                        <Components components={components} className={id} />
                    </Col>
                </Row>
            </PageCenter>
        </Page>
    )
}
